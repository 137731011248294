@import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap');

*:focus {
  outline:none;
}
*, *:before, *:after {
  box-sizing: border-box;
}
body {
  background: #232323;
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
html, body {
  height: 100%;
  overflow-y: hidden;
}
#root {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
a {
  color: unset;
  text-decoration: none;
}
p, li {
  font-family: 'Rajdhani';
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, .1); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .3); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, .7); 
}